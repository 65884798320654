<template>
  <section class="login-content bg-login-img posicionLogin" @mousemove="handleMouseMove" @mouseleave="resetTransform">
    <!-- <img src="../../../assets/images/login/02.png" class="lb-img" alt="">
         <img src="../../../assets/images/login/03.png" class="rb-img" alt=""> -->

    <div class="container h-100">
      <b-row class="align-items-center justify-content-center h-100">
        <div class="col-12">
          <b-row class="align-items-center">
            <div class="col-lg-6 mb-lg-0 mb-4 mt-lg-0 mt-4 responsive-xl-md content-logo principal">
              <img src="/img/HXWhite.png" class="img-fluid w-80 display-dark logo" alt="HXLogo-icon" :style="logoStyle" />
              <img src="/img/HXWblack.png" class="img-fluid w-80 display-light logo" alt="HXLogo-icon" :style="logoStyle" />
              <!-- <img src="../../../assets/images/login/01.png" class="img-fluid w-80" alt="" /> -->
            </div>
            <b-col lg="6 " class="card p-5 principal" style="border-radius: 5px">
              <span class="line"></span>
              <span class="line"></span>
              <span class="line"></span>
              <span class="line"></span>
              <!-- <img src="/img/HXLogoWhite.png" class="img-fluid rounded-normal " alt="logo" style="width: 100%; " /> -->
              <!-- <img src="/img/HXLogoBlack.png" class="img-fluid rounded-normal display-light" alt="logo" style="width: 90%" /> -->
              <img class="mb-sm-2 mb-0 p-md-5 display-dark" src="/img/HXLogoWhite.png" alt="HXLogo" />
              <img class="mb-sm-2 mb-0 p-md-5 display-light" src="/img/HXLogoBlack.png" alt="HXLogo" />

              <!-- <p>To Keep connected with us please login with your personal info.</p> -->
              <!-- <form @submit.prevent="login"> -->
              <form @submit.prevent="loginGetEmpresaPersona()">
                <b-row>
                  <b-col lg="12" v-if="paso2 == false">
                    <label class="ml-1">Email</label>
                    <div class="floating-label form-group">
                      <input required @focus="changeColorFocus" @blur="handleUnFocus" class="form-control" id="email" type="email" placeholder=" " v-model="Usuario" />
                    </div>
                    <button type="submit" class="btn-block btn-secondary py-2">Ingresar</button>
                  </b-col>
                </b-row>
              </form>

              <form @submit.prevent="loginGetPersona()">
                <b-row>
                  <b-col lg="12" v-if="paso2 == true">
                    <label class="ml-1">Ingrese el código de acceso que se envió a su correo electrónico:</label>
                    <div class="floating-label form-group">
                      <input
                        v-on:keyup.enter="onEnter()"
                        @focus="changeColorFocus"
                        @blur="handleUnFocus"
                        required
                        class="form-control"
                        type="password"
                        placeholder=" "
                        v-model="Contrasena"
                      />
                    </div>
                    <div v-if="terminosYCondiciones.hasOwnProperty('texto')" class="center mb-3" style="display: flex">
                      <vs-checkbox required v-model="aceptoTerminos"> </vs-checkbox>
                      <span class="ml-3 pointer" @click="checkAceptoTerminos()">Términos y condiciones y aviso de privacidad</span>
                    </div>
                    <button type="submit" class="btn-block btn-secondary py-2">Ingresar</button>
                  </b-col>

                  <!-- <b-col lg="6" >
                                 <div class="custom-control custom-checkbox mb-3">
                                    <input type="checkbox" class="custom-control-input" id="customCheck1">
                                    <label class="custom-control-label text-light mp-1" for="customCheck1">Recordar</label>
                                 </div>
                              </b-col> -->
                  <!-- <b-col lg="6" >
                                 <router-link to="/auth/recover-password" class="text-light float-right">¿Olvidó su contraseña?</router-link>
                              </b-col> -->
                </b-row>
              </form>
              <span class="mb-2">{{ mensaje }}</span>

              <p class="mt-3 text-light">
                <!-- Create an Account <router-link to="/auth/sign-up" class="text-primary">Sign Up</router-link> -->
              </p>
              <div class="row">
                <div class="col-12 text-center mt-5">
                  <span class="text-light pt-5">20241214</span><br />
                  <span class="text-light pt-5">Helexium ® 2024</span>
                </div>
              </div>
              <!-- </form> -->
            </b-col>
          </b-row>
        </div>
      </b-row>
    </div>

    <b-modal id="tyc-modal" title="Términos y Condiciones" size="xl" hide-footer>
      <div class="row px-5">
        <div class="col-md-12 content ql-editor" v-html="terminosYCondiciones.texto"></div>
      </div>
    </b-modal>
    <ParticlesLogin />
  </section>
</template>
<script>
import Swal from "sweetalert2";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import ParticlesLogin from "@/components/particles/particles";

import { mapActions, mapMutations, mapState } from "vuex";
export default {
  name: "SignIn",
  components: {
    ParticlesLogin,
  },

  data() {
    return {
      xAxis: 0,
      yAxis: 0,
      Usuario: "", // "admindemo@testing.com", // "RRHH4@testing.com", //
      Contrasena: "",
      mensaje: "",
      paso2: false,
      PersonaAct: {},
      terminosYCondiciones: {},
      aceptoTerminos: false,
      colorBorder: "rgb(242, 19, 67)",
    };
  },
  mounted() {
    localStorage.removeItem("setPersonaAct");
    localStorage.removeItem("tk");
    // limpiar datos de autenticacion de sisi
    localStorage.removeItem("sisiAuth");

    const isDarkModeActive = JSON.parse(localStorage.getItem("isdark")) == null ? false : JSON.parse(localStorage.getItem("isdark"));
    const body = document.querySelector("body");

    if (isDarkModeActive) {
      // document.body.classList.add("dark");
      body.classList.add("dark");
    } else {
      // alert(isDarkModeActive)
      body.classList.remove("dark");
    }
  },
  computed: {
    ...mapState("pesv", ["credential"]),
    logoStyle() {
      return {
        transform: `rotateY(${this.xAxis}deg) rotateX(${this.yAxis}deg)`,
      };
    },
  },
  methods: {
    ...mapMutations("pesv", ["setSisiAuth"]),
    ...mapActions("pesv", ["useRequestSisi"]),
    handleMouseMove(event) {
      const xAxis = (window.innerWidth / 2 - event.pageX) / 50;
      const yAxis = (window.innerHeight / 2 - event.pageY) / 25;
      this.xAxis = xAxis;
      this.yAxis = yAxis;
    },
    resetTransform() {
      this.xAxis = 0;
      this.yAxis = 0;
    },
    async authSisi() {
      // Autenticar con Sisi
      const authResponse = await this.useRequestSisi({
        method: "POST",
        path: "autenticacion/inicio-sesion",
        body: this.credential,
      });

      if (!authResponse.ok) {
        return;
      }

      // Guardar credenciales en el store y en localStorage
      this.setSisiAuth(authResponse.data);
    },
    checkAceptoTerminos() {
      this.$bvModal.show("tyc-modal");
    },
    async getTerminosYCondiciones(id) {
      try {
        let res = await this.$store.dispatch("hl_get", {
          path: "TerminosYCondiciones/GetTerminosYCondicionesPersonaPaisId/" + id,
        });
        this.terminosYCondiciones = res;
      } catch (error) {
        this.terminosYCondiciones = {};
      }
    },
    async onEnter() {
      await this.loginGetPersona();
    },
    async loginGetPersona() {
      let data = { Usuario: this.Usuario, Contrasena: this.Contrasena };
      try {
        this.$isLoading(true);
        let res = await this.$store.dispatch("hl_post", {
          path: "Authentication/LoginPersona",
          data: data,
        });

        if (res.id) {
          localStorage.setItem(
            "credenciales",
            JSON.stringify({
              personaId: res.id,
              dynamicPassword: this.Contrasena,
            })
          );

          this.PersonaAct = res;
          let tokenres = await this.$store.dispatch("posthlw", {
            path: "Authentication/GetToken",
            data: data,
          });
          this.$isLoading(false);

          //tokenres = await tokenres.json();
          this.$store.commit("setToken", tokenres.token);
          this.$store.commit("setPersonaAct", res);
          localStorage.setItem("tk", tokenres.token);
          localStorage.setItem("user", JSON.stringify(res.data));
          this.$store.dispatch("wrapperSetUserLoggedIn", res);

          //aceptando terminos y condiciones
          let dataTerminos = {
            PersonaId: res.id,
            fecha: this.$moment.tz(new Date(), "America/Bogota").format("YYYY-MM-DDTHH:mm"),
          };
          await this.$store.dispatch("hl_post", {
            path: "TerminosYCondiciones/AceptaTerminosYCondiciones/",
            data: dataTerminos,
          });
          this.$router.push("dashboard");
          this.mensaje = "";
          //return true;
        } else {
          this.PersonaAct = {};
          this.$isLoading(false);
          Swal.fire("Credenciales incorrectas!", "", "warning");

          //this.mensaje = "Credenciales incorrectas.";
          //return false;
        }

        // autenticar con sisi
        await this.authSisi();
      } catch (error) {
        console.log("error", error);
        this.$isLoading(false);
        this.PersonaAct = {};
        //return false;
      }
    },
    async loginGetEmpresaPersona() {
      this.$isLoading(true);
      // let data = JSON.stringify({ Correo: this.Usuario});
      try {
        let res = await this.$store.dispatch("gethlw", {
          path: "Persona/GetEmpresaPersona/" + this.Usuario,
        });
        this.$isLoading(false);
        console.log("loginGetEmpresaPersona", res);
        if (res.id) {
          this.PersonaAct = res;
          this.paso2 = true;
          this.mensaje = "";
          // this.$router.push("dashboard");
          //return true;
          await this.getTerminosYCondiciones(this.Usuario);
        } else {
          this.PersonaAct = {};
          this.mensaje = "Credenciales incorrectas.";
          //return false;
        }
      } catch (error) {
        this.$isLoading(false);
        this.PersonaAct = {};
        //return false;
      }
    },
    async login() {
      this.$isLoading(true);
      let data = JSON.stringify({
        Usuario: this.Usuario,
        contrasena: this.Contrasena,
      });
      try {
        let res = await this.$store.dispatch("hl_login", { data: data });
        this.$isLoading(false);
        //console.log("rs", res);
        if (res.id) {
          this.PersonaAct = res;
          this.$router.push("dashboard");
          //return true;
        } else {
          this.PersonaAct = {};
          this.mensaje = "Credenciales incorrectas.";
          //return false;
        }
      } catch (error) {
        this.$isLoading(false);
        this.PersonaAct = {};
        //return false;
      }
    },
    changeColorFocus() {
      this.colorBorder = "#4677d5";
    },
    handleUnFocus() {
      this.colorBorder = "rgb(242, 19, 67)";
    },
  },
};
</script>

<style>
:root {
  --color1-dark: #1e2023;
  --color1-light: #ffffff;
  --color2: rgb(242, 19, 67);
  --color3: #4677d5;
}

.content-logo {
  animation: bounce 2s infinite ease-in-out;

  &::after {
    content: "";
    position: absolute;
    bottom: -10px;
    left: 20%;
    width: 40%;
    height: 20px;
    background: rgba(0, 0, 0, 0.2);
    filter: blur(2px);
    border-radius: 50%;
    transition: transform 0.2s ease-out, opacity 0.2s ease-out;
    animation: shadowBounce 2s infinite ease-in-out;
  }

  .dark &::after {
    background: rgba(0, 0, 0, 0.5);
  }

  & .logo {
    filter: drop-shadow(2.5px 2.5px 5px rgba(0, 0, 0, 0.2));
    transition: transform 0.2s ease-out;
    transform-style: preserve-3d;
  }
}

.card {
  /* box-shadow: 0 20px 50px rgba(0, 0, 0, 0.2); */
  overflow: hidden;
}

.card .line:nth-child(1) {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background: v-bind("`linear-gradient(to right, var(--color1-light), ${colorBorder})`");

  .dark & {
    background: v-bind("`linear-gradient(to right, var(--color1-dark), ${colorBorder})`");
  }

  animation: animate1 2s linear infinite;
}

@keyframes animate1 {
  0% {
    transform: translateX(-100%);
  }

  100% {
    transform: translateX(100%);
  }
}

.card .line:nth-child(2) {
  position: absolute;
  top: 0;
  right: 0;
  width: 2px;
  height: 100%;
  background: v-bind("`linear-gradient(to bottom, var(--color1-light), ${colorBorder})`");

  .dark & {
    background: v-bind("`linear-gradient(to bottom, var(--color1-dark), ${colorBorder})`");
  }
  animation: animate2 2s linear infinite;
  animation-delay: 1s;
}

@keyframes animate2 {
  0% {
    transform: translateY(-100%);
  }

  100% {
    transform: translateY(100%);
  }
}

.card .line:nth-child(3) {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background: v-bind("`linear-gradient(to left, var(--color1-light), ${colorBorder})`");

  .dark & {
    background: v-bind("`linear-gradient(to left, var(--color1-dark), ${colorBorder})`");
  }

  animation: animate3 2s linear infinite;
}

@keyframes animate3 {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(-100%);
  }
}

.card .line:nth-child(4) {
  position: absolute;
  top: 0;
  left: 0;
  width: 2px;
  height: 100%;
  background: v-bind("`linear-gradient(to top, var(--color1-light), ${colorBorder})`");

  .dark & {
    background: v-bind("`linear-gradient(to top, var(--color1-dark), ${colorBorder})`");
  }

  animation: animate4 2s linear infinite;
  animation-delay: 1s;
}

@keyframes animate4 {
  0% {
    transform: translateY(100%);
  }

  100% {
    transform: translateY(-100%);
  }
}

@keyframes bounce {
  0%,
  100% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-10px);
  }
}

@keyframes shadowBounce {
  0%,
  100% {
    transform: scale(1);
    opacity: 0.2;
  }

  50% {
    transform: scale(0.8);
    opacity: 0.4;
  }
}

.principal {
  z-index: 100;
  position: relative;
}
</style>
